import { createAction } from 'redux-actions';
import constants from './constants';

// export const loginModelFlag = createAction(constants.LOGIN_MODEL_FLAG);
export const loaderStatusChange = createAction(constants.LOADER);
export const emailBanners = createAction(constants.EMAIL_BANNER);
export const mainpageChange = createAction(constants.MAIN_PAGE);
export const termsMessage = createAction(constants.TERMS);
export const privacyMessage = createAction(constants.PRIVACY);
export const signInRequest = createAction(constants.LOGIN_REQUEST);
export const signInSuccess = createAction(constants.LOGIN_REQUEST_SUCCESS);
export const signInError = createAction(constants.LOGIN_REQUEST_ERROR);
export const signInClear = createAction(constants.CLEAR_FLAG);

export const toastMessage = createAction(constants.TOAST);
export const clearToast = createAction(constants.CLEAR_TOAST);

export const logoutUser = createAction(constants.CLEAR_FLAG);
export const listUser = createAction(constants.LIST_USER);
export const deleteUserList = createAction(constants.DELETE_USER);
export const editUserList = createAction(constants.EDIT_USER);

export const signUpSuccess = createAction(constants.SIGN_UP_SUCCESS);
export const setupDesktopView = createAction(constants.IS_DESKTOP_VIEW)
export const setupMobileShape = createAction(constants.IS_INCLUDE_SHAPE)
export const fetchComponentId = createAction(constants.IS_FETCH_ID)