import * as actions from "../../redux/actions";

export const LoaderAction = (payload) => {
  return async (dispatch) => {
    dispatch(actions.loaderStatusChange(payload));
  };
};


export const emailBanner = (payload) => {
  return async (dispatch) => {
    dispatch(actions.emailBanners(payload));
  };
};
