import { useState } from 'react'
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useDispatch } from 'react-redux'
import { Label } from 'reactstrap'
import { contactInquiry } from '../../Api/UserApi'
import { toastMessage } from '../../redux/actions'
import { customValidation } from '../../utils/regex'
import CustomButton from '../Common/CustomButton'
import CustomTextarea from '../Common/CustomTextarea'
import TextField from '../Common/TextField'
import { X } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { defaultArabicCode, defaultCode } from '../../utils/customFunction'
import PhoneInput from '../Common/PhoneInput'
import { purify } from '../../utils/dompurify'



const initialcontacts = {
    name: "",
    email: "",
    phone_number: "",
    company_name: "",
    message: ""
}

const Feedback = () => {

    const {i18n, t } = useTranslation()
    const dispatch = useDispatch();
    const [contact, setContact] = useState(initialcontacts)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})
    const [countryCode, setCountryCode] = useState( i18n.language === "ar" ? defaultArabicCode : defaultCode);

    const handleChange = (e) => {
        const { name, value: changedValue } = e.target
        const value = purify(changedValue)
        setError({
            ...error, [name]: customValidation(name, value)
        })
        setContact({ ...contact, [name]: value })
    };

    const sendContactDetails = async () => {
        setLoading(true)
        try {
            const response = await contactInquiry({ ...contact, phone_number: `${countryCode.value}${contact.phone_number}` })
            if (response?.data) {
                setLoading(false)
                dispatch(toastMessage({
                    status: true,
                    message: t('thankYouContactUs'),
                    type: "success",
                }));
                setContact(initialcontacts)
                setCountryCode(defaultCode)
            }
        } catch (error) {
            setLoading(false)
            dispatch(toastMessage({
                status: true,
                message: error.message || error?.response?.data?.detail[0].msg === 'should be valid phone number' ? t('enterValidNumber') : `${error?.response?.data?.detail}` || t("somthingWentWrong"),
                type: "error",
            }));
        }
    };

    const onContactSubmit = (e) => {
        e.preventDefault()
        let validationErrors = {};
        Object.keys(contact).forEach((name) => {
            const error = (name === "phone_number") ? isValidPhoneNumber(countryCode.value + contact["phone_number"]) ? "" : "enterValidNumber" : customValidation(name, contact[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors);
            return;
        }
        sendContactDetails()
    };

    const handleClose = (name) => {
        setContact({
            ...contact, [name]: ''
        })
    };

    const handlePhoneNumber = (e) => {
        const phoneValidation = () => isValidPhoneNumber(countryCode.value + e.target.value)
        const { value } = e.target
        setContact({ ...contact, phone_number: value || '' })
        setError({
            ...error,
            phone_number: value?.length ? (!phoneValidation() ? t('enterValidNumber') : '') : ''
        })
    }

    const handlePhoneCode = (e) => {
        const phoneValidation = () => isValidPhoneNumber(e.value + contact.phone_number)
        setCountryCode(e)
        setError({
            ...error,
            phone_number: (!phoneValidation() ? t('enterValidNumber') : '')
        })
    };



    return (
        <div id="feedback">
            <Container>
                <Form onSubmit={onContactSubmit} >
                    <Row>
                    <Col md={12}>
                        <h2>{t("contactUs")}</h2>
                    </Col>
                        <Col sm={6}>
                                <div className='feedback-field'>
                                    <Label>{t('name')}</Label>
                                    <TextField type='text' name="name" value={contact.name} onChange={handleChange} placeholder={t('typeYourName')} />
                                    {contact.name && <span className="custom-close" onClick={() => handleClose('name')} ><X /></span>}
                                    <p className='text-danger m-0'>{error['name'] && t(`${error['name']}`)}</p>
                                </div>
                        </Col>
                        <Col sm={6}>
                                <div className='feedback-field'>
                                    <Label>{t('email')}</Label>
                                    <TextField type='email' name="email" value={contact.email} onChange={handleChange} placeholder={t('enterYourEmail')} />
                                    {contact.email && <span className="custom-close" onClick={() => handleClose('email')} ><X /></span>}
                                    <p className='text-danger m-0'>{error['email'] && t(`${error['email']}`)}</p>
                                </div>
                        </Col>
                        <Col sm={6}>
                                <div className='feedback-field'>
                                    <Label>{t('phone')}</Label>
                                    <div className="countries-code">
                                        <PhoneInput value={contact.phone_number} code={countryCode} {...{ handleCode: handlePhoneCode, handleChange: handlePhoneNumber }} />
                                        {contact.phone_number && <span className="custom-close" onClick={() => handleClose('phone_number')} ><X /></span>}
                                    </div>
                                    <p className='text-danger m-0'>{error['phone_number'] && t(`${error['phone_number']}`)}</p>
                                </div>
                        </Col>
                        <Col sm={6}>
                                <div className='feedback-field'>
                                    <Label>{t('companyName')}</Label>
                                    <TextField type='text' name="company_name" value={contact.company_name} onChange={handleChange} placeholder={t('typeYourComanyName')} />
                                    {contact.company_name && <span className="custom-close" onClick={() => handleClose('company_name')} ><X /></span>}
                                    <p className='text-danger m-0'>{error['company_name'] && t(`${error['company_name']}`)}</p>
                                </div>
                        </Col>
                        <Col sm={12}>
                                <div className='text-message'>
                                    <Label>{t('message')}</Label>
                                    <CustomTextarea rows="3" name="message" value={contact.message} onChange={handleChange} placeholder={t('enterYourMessage')}></CustomTextarea>
                                    <p className='text-danger m-0'>{error['message'] && t(`${error['message']}`)}</p>
                                </div>
                        </Col>
                        <Col sm={12} className='text-right'>
                                <CustomButton type='submit' disabled={loading} >{loading ? <Spinner className='custom-loader' animation="border" /> : ''}{t('submit')}</CustomButton>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div >
    )
}

export default Feedback
