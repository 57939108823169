import constants from "../constants";
export const initialState = {
  pagedata: [],
  termscondition: {},
  privacy: {},
  userList: [],
  editData: {},
  isDesktopView: true,
  isIncludeMobileShape: false,
  isFetchId: null
};
const mainpageData = (state = initialState, action) => {
  switch (action.type) {
    case constants.MAIN_PAGE:
      return {
        ...state,
        pagedata: action.payload,
      };
    case constants.TERMS:
      return {
        ...state,
        termscondition: action.payload
      }
    case constants.IS_DESKTOP_VIEW:
      return {
        ...state,
        isDesktopView: action.payload
      }
    case constants.IS_INCLUDE_SHAPE:
      return {
        ...state,
        isIncludeMobileShape: action.payload
      }
    case constants.IS_FETCH_ID:
      return {
        ...state,
        isFetchId: action.payload
      }
    case constants.PRIVACY:
      return {
        ...state,
        privacy: action.payload
      }
    case constants.LIST_USER:
      let abc = state.userList.concat(action.payload)
      return {
        ...state,
        userList: abc
      }
    case constants.DELETE_USER:
      const index = state.userList.findIndex(data => data.sub === action.payload)
      let data = state.userList
      data.splice(index, 1)
      return {
        ...state,
        userList: data
      }
    case constants.EDIT_USER:
      return {
        ...state,
        editData: action.payload
      }
    default:
      return state;
  }
};
export default mainpageData;
