import React, { Suspense, useEffect, useState } from "react";
import Routing from "./Routes/Routing";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { isLoggedIn } from "./utils/authentication";
import { useDispatch } from 'react-redux'
import DashboardHeaders from "./Component/Dashboard/DashboardHeaders";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive-style.css";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Component/Landing/Loader";
import { jssPreset, StylesProvider } from "@material-ui/core";
import { create } from "jss";
import rtl from "jss-rtl";
import { arabicClass, handleMobileComponent, handleViewComponent } from "./utils/customFunction";
import { useTranslation } from "react-i18next";
import { setDesktopView, setupMobileShapeAdd } from "./redux/MainPage/action";

const App = () => {

  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const toaster = useSelector((state) => state.toastReducer);

  const reSize = () => {
    const newWidth = window.innerWidth;
    dispatch(setDesktopView(!handleViewComponent(newWidth || window.innerWidth)))
    dispatch(setupMobileShapeAdd(handleMobileComponent(newWidth || window.innerWidth)))
  };

  window.onresize = reSize

  const toasterModify = (type, msg) => {
    if (type === "success") {
      toast.success(msg, {
        style: { backgroundColor: "#2dce89", color: "white" },
      });
    } else {
      toast.warn(msg, {
        style: { backgroundColor: "#f5365c", color: "white" },
      });
    }
  };

  useEffect(() => {
    loading && setLoading(false)
    reSize()
    setTimeout(() => {
      arabicClass(i18n.language)
      setLoading(true)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  useEffect(() => {
    if (toaster.status) {
      toasterModify(toaster.type, toaster.message);
    }
  }, [toaster]);

  const loginCheck = isLoggedIn();

  return (
    <div className="App">
      {loading ? <StylesProvider jss={jss} >
        <Router>
          <ToastContainer style={{ width: "auto" }} />
          {loginCheck && <DashboardHeaders />}
          <Suspense fallback={<Loader />}>
            <Routing />
          </Suspense>
        </Router>
      </StylesProvider> : <Loader />}
    </div>
  );
};

export default App;
