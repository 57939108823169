import React from 'react'
import { Modal } from 'react-bootstrap'

const CustomModal = ({ modalShow, handleClose, dialogClassName, ...props }) => {
    const handleScroll = () => {
        let focusRemove = document.querySelectorAll('.signup-wrap input')
        focusRemove.length > 0 && focusRemove.forEach((data, index) => {
            return data.blur()
        })
    }

    return (
        <Modal dialogClassName={dialogClassName} show={modalShow} onHide={handleClose} onWheel={handleScroll} onTouchMove={handleScroll} onTouchEnd={handleScroll}>
            {/* <Modal.Header closeButton>
                <Modal.Title >
                    <div className="modal-logo" onClick={handleClose}>
                        <img src={'https://s3-eu-west-1.amazonaws.com/microsite-be-dev/1643967171477_sign_up_logo.png'} alt="logo" />
                    </div>
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body className="modify-modal">
                {props.children}
            </Modal.Body>
        </Modal>
    )
}

export default CustomModal
