import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Feedback from "./Feedback";
import { Link as ScrollTo } from "react-scroll";
import { Link } from "react-router-dom";

const generalLink = [
  { text: "howItWorks",isRedirect: true, link: "/"  },
  { text: "features", isRedirect: true, link: "/" },
  { text: "pricing", isRedirect: true, link: "/pricing-plans" },
  { text: "faq", isNewTab: true, link: "/faq-page" },
];

const resourcesLink = [
  { text: "contact" },
  { text: "Terms & Conditions", isNewTab: true, link: "/terms-and-conditions" },
  { text: "Privacy Policy", isNewTab: true, link: "/privacy-policy" },
];

const Footer = () => {
  const {i18n, t } = useTranslation();
  const history = useHistory();
  const year = new Date().getFullYear();

  const handleRedirection = (isRedirect, isNewTab, link) => {
    if (i18n.language === 'ar') link = `${link}/ar`;
    isRedirect && history.push(link)
    isNewTab && window.open(link)
  }

  return (
    <footer>
      <Container>
        <Row>
          <Col lg={6}>
            <Row>
              <Col xs={6}>
                <div className="general-link">
                  <h2>{t("general")}</h2>
                  <ul>
                    {generalLink.map((item, i) => {
                      if ((history.location.pathname !== "/" || history.location.pathname !== "/ar") && (item.text === "howItWorks" || item.text === "ourFeatures")){
                        return <Link to={{
                          pathname: i18n.language === "ar" ? "/ar": "/",
                          state:{
                              elementid: item.text === "howItWorks" ? "#howdoeswork .container" : "#notification-email"
                          }
                        }}>
                            <li key={i} >{t(item.text)}</li>
                        </Link>
                      }

                       else if (item.text === "howItWorks") return <ScrollTo to="howdoeswork" spy={true} smooth={true} duration={800} offset={50}>
                          <li> {t(item.text)}</li>  
                        </ScrollTo>
                       else if(item.text === "ourFeatures") return <ScrollTo to="notification-email" spy={true} smooth={true}duration={800} offset={0}>
                                <li> {t(item.text)}</li>      
                        </ScrollTo>
                       else{
                         return <li onClick={() => handleRedirection(item.isRedirect, item.isNewTab, item.link)} key={i} >{t(item.text)}</li>;
                       }
                    })}
                  </ul>
                </div>
              </Col>
              <Col xs={6}>
                <div className="resources-link">
                  <h2>{t("resources")}</h2>
                  <ul>
                    {resourcesLink.map((item, i) => {
                      if (item.text === "contact") return <ScrollTo to="feedback" spy={true} smooth={true} duration={800} offset={50}><li key={i} >{t(item.text)}</li></ScrollTo>
                      return <li onClick={() => handleRedirection(false, item.isNewTab, item.link)} key={i} >{t(item.text)}</li>;
                    })}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="px-0">
            <Feedback />
          </Col>
        </Row>
      </Container>
      <div className="top-footer">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="rights-reserved text-center">
                © {year} {t("allRightsReserved")}
              </div>
            </Col>
            {/* <Col lg={8}>
              <ul className="footer-link">
                {footer.settings?.footer_menu.map((data, index) => {
                  return (
                    <li key={index}>
                      <a href={data.link} target="_blank" rel="noreferrer">
                        {t(`${data.title}`)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Col> */}
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
