import { useState, useEffect } from 'react';
import { Col, Container, Card, Button, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import FaqImg from "../../assets/images/faq.svg"
import { useTranslation } from "react-i18next";
import Header from '../Header/Header';
import Footer from './Footer';


const FaqGiftCards = ({ isArabic, pathName }) => {

    const { t } = useTranslation()
    const [activeKey, setActiveKey] = useState("");

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const faqGiftCard = [
        {
            title: t('whatIsGiftcardsby'),
            contain: t('whatIsGiftcardsbyText'),
            eventKey: "1",
        },
        {
            title: t('giftCardsBenifit'),
            contain: t('giftCardsBenifitText'),
            eventKey: "2",
        },
        {
            title: t('whatIsGiftCard'),
            contain: t('whatIsGiftCardText'),
            eventKey: "3",
        },
        {
            title: t('howToRedeemGiftCard'),
            contain: t('howToRedeemGiftCardText'),
            eventKey: "4",
        }
    ]

    const handleClick = (data) => {
        const { eventKey } = data
        setActiveKey(activeKey === eventKey ? '' : eventKey)
    }

    return (
        <div className="modify_header">
            <Header isArabic={isArabic} path={pathName} modify />
            <div className="banner-heading">
                <h1> {t('frequentlyAskQuestions')} </h1>
            </div>
            <div id="faq-gift-card">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="faq-accordion">
                                <Accordion activeKey={activeKey} >
                                    {
                                        faqGiftCard.map((item, faq) => {
                                            return (
                                                <Card key={faq} className="accordion-box">
                                                    <Card.Header>
                                                        <h4> {item.title} </h4>
                                                        <Accordion.Toggle as={Button} onClick={() => handleClick(item)} variant="link" eventKey={item.eventKey}>
                                                            {
                                                                activeKey === item.eventKey ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>
                                                            }
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={item.eventKey}>
                                                        <Card.Body>
                                                            {item.contain}
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                        </Col>
                        <Col md={6} className='faq-section-img'>
                            <div className='text-right faq-images'>
                                <img src={FaqImg} alt='img' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
            <Footer />
        </div>
    )
}

export default FaqGiftCards