import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CustomButton from '../Common/CustomButton'
import { loggedOutUser } from '../../utils/authentication';
import { useHistory } from 'react-router-dom';


const DashboardHeaders = () => {

    const history = useHistory()

    const LogoutHandle = () => {
        history.push("/")
        loggedOutUser()
    }

    return (
        <>
            <header>
                <Navbar expand="lg" fixed="top">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Link
                                    className="nav-link"
                                    to="/admin/user-list"
                                    duration={800}
                                    offset={-70}
                                >
                                    Add User
                                </Link>
                                <Link
                                    className="nav-link"
                                    to="/admin/user-dashboard"
                                    duration={800}
                                    offset={-70}
                                >
                                    User Dashboard
                                </Link>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="header-btn">
                            <CustomButton handalClick={LogoutHandle}>LogOut</CustomButton>
                        </div>
                    </Container>
                </Navbar>
            </header>
        </>
    )
}

export default DashboardHeaders
