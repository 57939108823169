import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const LanguageSelector = ({ setMenuExpand, isArabic, isHome, path, ...props }) => {

    const { i18n } = useTranslation()
    const history = useHistory()
    const [selectedLanguage, setSelectedLanguage] = useState("en")
    const language = localStorage.getItem("i18nextLng")

    useEffect(() => {
        if (language) {
            changeLanguage(language);
            handleViewChanges(language)
            setSelectedLanguage(language)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleLanguage(isArabic ? "ar" : "en")
        // eslint-disable-next-line
    }, [isArabic])

    useEffect(() => {
        setSelectedLanguage(i18n.language)
    }, [i18n.language])

    const handleLanguage = (value) => {
        setSelectedLanguage(value)
        changeLanguage(value);
        handleViewChanges(value)
    }

    const handleSelect = (event) => {
        history.push(`${isHome ? "" : path}/${event.target.value}`)
        handleLanguage(event.target.value)
        setMenuExpand(false)
    }

    const handleViewChanges = (lan) => {
        lan === "ar" ? document.body.classList.add('rtl') : document.body.classList.remove('rtl')
    }

    const changeLanguage = (lng) => i18n.changeLanguage(lng)

    return (
        <>
            <div {...props} className="language-select">
                <i onClick={() => setMenuExpand(true)} className="fas fa-globe mr-2"></i>
                <div className="custom-select-dropdown">
                    <select value={selectedLanguage} onChange={handleSelect}>
                        <option value="en">English</option>
                        <option value="ar">عربى</option>
                    </select>
                </div>
            </div>
        </>
    )
}

export default LanguageSelector