import constants from "../constants";

const initialState = {
    isSuccess: false,
    loggedUser: {},
    signUpSucess: false
};

const signIn = (state = initialState, action) => {
    switch (action.type) {
        case constants.LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                loggedUser: action.payload
            };
        case constants.LOGIN_REQUEST_ERROR:
            return {
                ...state,
                isSuccess: false,
            };
        case constants.CLEAR_FLAG:
            return {
                ...state,
                loggedUser: {},
                isSuccess: false
            }
        case constants.SIGN_UP_SUCCESS:
            return {
                ...state,
                signUpSucess: action.payload
            }
        default:
            return state;
    }
};
export default signIn;