import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { isEnglishContent } from "../../utils/customFunction";
import { t } from "i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import icon from "../../assets/images/icon.png"

export default function CustomInputPassword({ name, value, label, handleChange, error, ...props }) {


  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      "& .MuiSvgIcon-root": {
        fill: "#1B3D9A",
      },
    },
    customPassword: {
      position: "relative",
      marginBottom: "16px",
      "& > *": {
        margin: 0,
        width: "100%",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#cccccc",
        borderRadius: "4px",
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#cccccc",
      },
      "& .MuiOutlinedInput-input": {
        padding: "18px 14px",
        paddingRight: isEnglishContent(i18n.language) ? "40px" : "14px",
        paddingLeft: isEnglishContent(i18n.language) ? "14px" : "40px",
        [theme.breakpoints.down('480')]: {
          padding: '14px 14px',
          paddingRight: isEnglishContent(i18n.language) ? "40px" : "14px",
          paddingLeft: isEnglishContent(i18n.language) ? "14px" : "40px",
        },
      },
      "& .MuiInputLabel-outlined": {
        transform: " translate(14px, 20px) scale(1)",
        [theme.breakpoints.down("480")]: {
          transform: " translate(14px, 17px) scale(1)",
          fontSize: "14px",
        },
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
        fontWeight: 600,
      },
      "& .MuiFormLabel-root": {
        color: "#626262",
        right: isEnglishContent(i18n.language) ? "inherit" : "28px",
        left: isEnglishContent(i18n.language) ? "0" : "inherit",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1B3D9A",
        fontWeight: 500,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1B3D9A",
      },
      "& .MuiInputBase-input": {
        color: "#000000 !important",
        fontWeight: 400,
      },
      "& MuiFormHelperText-root": {
        color: " #dc3545!important",
      },
    },
    clearIcon: {
      color: "#ff302d",
      position: "absolute",
      cursor: "pointer",
      top: "18px",
      right: "46px",
      width: "auto",
      [theme.breakpoints.down("479")]: {
        top: "11px",
      },
      "& svg": {
        fontSize: "30px",
        [theme.breakpoints.down("479")]: {
          fontSize: "26px",
        },
        [theme.breakpoints.down("425")]: {
          fontSize: "24px",
        },
      },
    },
  }));

  const { i18n } = useTranslation()

  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const theme = createTheme({ direction: isEnglishContent(i18n.language) ? 'ltr' : 'rtl' });
  console.log(error);
  return (
    <ThemeProvider theme={theme} >
      <div className={classes.customPassword} dir={isEnglishContent(i18n.language) ? 'ltr' : 'rtl'}>
        <FormControl {...props} className={clsx(classes.margin, classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={value}
            name={name}
            autoComplete="off"
            onChange={handleChange}
            endAdornment={
              <InputAdornment className={isEnglishContent(i18n.language) ? "" : "d-none"} position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            startAdornment={
              <InputAdornment className={isEnglishContent(i18n.language) ? "d-none" : ""} position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        {
          error === "" || error === undefined ? <div className="ml-1">
            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled" >{t("passwordValidation")}</Tooltip>}>
              <img style={{ width: "5%" }} src={icon} alt="icon" />
            </OverlayTrigger>
          </div> : <p className="input-error-msg">{error && t(`${error}`)}</p>
        }
      </div>
    </ThemeProvider>
  );
}
