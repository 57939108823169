import axios from "axios"
// import { getSession } from "../Component/UserPool/Cognito"

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER_URL}`,
})

instance.interceptors.request.use(
    async (config) => {
        config.headers.Accept = "application/json";
        config.headers["Content-Type"] = "application/json";
        return config
    },
    error => Promise.reject(error)
)

instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (
            [401, 402, 403].includes(error.response.status)
        ) { 
            console.log('error', error);
            localStorage.clear()
        }
        return Promise.reject(error)
    }
)

export default instance
