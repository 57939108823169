import React from 'react'
import { makeStyles } from '@material-ui/core';
import clsx from "clsx";

const useColorlibStepIconStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#3257C1",
        zIndex: 1,
        color: "#fff",
        width: 32,
        height: 32,
        fontSize: "18px",
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        [theme.breakpoints.down("481")]: {
            width: 30,
            height: 30,
            fontSize: "16px",
        }
    },
    active: {
        backgroundColor: "#FFDD1C",
        color: "#000",
    },
    completed: {
        backgroundColor: "#ffffff",
        color: "#181818",
    },
}));

const icons = { 1: "1", 2: "2", 3: "3", 4: "4" };

const Steps = ({ active, completed, ...props }) => {

    const classes = useColorlibStepIconStyles();

    return <div
        className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
        })}
    >
        {icons[String(props.icon)]}
    </div>
}

export default Steps