import constants from "../constants";
export const initialState = {
  status: false,
  email:""
};
const loader = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOADER:
      return {
        ...state,
        status: action.payload,
      };
    case constants.EMAIL_BANNER:
      return {
        ...state,
        email: action.payload
      }
    default:
      return state;
  }
};
export default loader;
