import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { isEnglishContent } from "../../utils/customFunction";
import { MenuItem } from "@material-ui/core";



export default function CustomSelectBox({ name, value, handleChange, label, data, error, setError, formData, setFormData, isHideClose, isHideError, classNames, ...props }) {

  const { i18n, t } = useTranslation()

  const useStyles = makeStyles((theme) => ({
    customSelect: {
      position: "relative",
      marginBottom: "2px",
      "& > *": {
        margin: 0,
        width: "100%",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#cccccc",
        borderRadius: "4px",
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#cccccc",
      },
      "& .MuiOutlinedInput-input": {
        padding: "18px 14px",
        paddingRight: isEnglishContent(i18n.language) ? "70px" : "14px",
        paddingLeft: isEnglishContent(i18n.language) ? "14px" : "70px",
        [theme.breakpoints.down('480')]: {
          padding: isEnglishContent(i18n.language) ? "14px 70px 14px 14px" : "14px 14px 14px 70px",
        },
      },
      '& .MuiInputLabel-outlined': {
        transform: ' translate(14px, 20px) scale(1)',
        [theme.breakpoints.down('480')]: {
          transform: ' translate(14px, 17px) scale(1)',
          fontSize: '14px',
        },
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
        fontWeight: 600,
      },
      "& .MuiFormLabel-root": {
        color: "#626262",
        right: isEnglishContent(i18n.language) ? "inherit" : "28px",
        left: isEnglishContent(i18n.language) ? "0" : "inherit",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1B3D9A",
        fontWeight: 500,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1B3D9A",
      },
      "& .MuiInputBase-input": {
        color: "#000000 !important",
        fontWeight: 400,
      },
      "& MuiFormHelperText-root": {
        color: " #dc3545!important",
      },
      "& .MuiSelect-select": {
        backgroundColor: "transparent",
      },
    },
    formControl: {
      width: "100%",
    },
    clearIcon: {
      color: "#ff302d",
      position: "absolute",
      cursor: "pointer",
      top: "18px",
      right: "24px",
      width: "auto",
      backgroundColor: "#fff",
      [theme.breakpoints.down("479")]: {
        top: "11px",
        right: "6px",
      },
      "& svg": {
        fontSize: "30px",
        [theme.breakpoints.down("479")]: {
          fontSize: "26px",
        },
        [theme.breakpoints.down("425")]: {
          fontSize: "24px",
        },
      },
    },
  }));

  const classes = useStyles();
  const theme = createTheme({ direction: isEnglishContent(i18n.language) ? 'ltr' : 'rtl' });

  const handleRemove = (item) => {
    setError({ ...error, [item]: "" });
    setFormData({ ...formData, [item]: "" });
  };

  return (
    <ThemeProvider theme={theme} >
      <div className={`${classes.customSelect} ${classNames}`} dir={isEnglishContent(i18n.language) ? 'ltr' : 'rtl'}>
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name={name}
            value={value}
            onChange={handleChange}
            label={label}
          >
            {data &&
              data.map((data, id) => {
                return (
                  <MenuItem value={data.value} key={id}>
                    {data.name}
                  </MenuItem>
                );
              })}
          </Select>
          {!isHideClose ? <>
            {value ? (
              <span className={`${classes.clearIcon} detail-custom-close`} onClick={() => handleRemove(name)}>
                {t('clear')}
              </span>
            ) : null}
          </> : null}
          {!isHideError ? <p className="input-error-msg">{error[name] && `${t(error[name])}`}</p> : null}
        </FormControl>
      </div>
    </ThemeProvider>
  );
}
