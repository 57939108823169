import client1 from '../assets/images/1.png';
import client3 from '../assets/images/3.png';
import client9 from '../assets/images/9.png';
import client10 from '../assets/images/10.png';
import client11 from '../assets/images/11.png';
import client12 from '../assets/images/12.png';
import client13 from '../assets/images/13.png';
import vc1 from '../assets/images/vc1.png';
import vc2 from '../assets/images/vc2.png';
import vc3 from '../assets/images/vc3.png';
import vc4 from '../assets/images/vc4.png';
import vc5 from '../assets/images/vc5.png';

export const removeByAttr = function (arr, attr, value) {
    let i = arr.length;
    while (i--) {
        if (arr[i]
            && arr[i].hasOwnProperty(attr)
            && (arguments.length > 2 && arr[i][attr] === value)) {

            arr.splice(i, 1);

        }
    }
    return arr;
}

export const handleViewComponent = (width) => {
    const isMobile = width <= 767
    return isMobile
}

export const isArabicMode = (data) => {
    return data === "ar"
}

export const handleMobileComponent = (width) => {
    const isAddedShape = width <= 487
    return isAddedShape
}

export const defaultCode = {
    "name": "United Arab Emirates",
    "value": "+971",
    "label": "+971",
    "code": "AE"
}
export const defaultArabicCode =    {
    "name": "Saudi Arabia",
    "value": "+966",
    "label": "+966",
    "code": "SA"
  }

export function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
}

export const partnerImgSlider = [
    {
        isArabic: true,
        Image: vc1
    },
    {
        isArabic: true,
        Image: vc2
    },
    {
        isArabic: true,
        Image: vc3
    },
    {
        isArabic: true,
        Image: vc4
    },
    {
        isArabic: true,
        Image: vc5
    },
    { Image: client13 },
    { Image: client1 },
    { Image: client3 },
    { Image: client10 },
    { Image: client11 },
    { Image: client9 },
    { Image: client12 }
]

export const textFeatures = [
    {
        id: 0,
        text: 'fullyAutomated',
        delay: 100,
        duration: 1.5,
        isShow: true,
        isMobile: true,
        offset: -200
    },
    {
        id: 1,
        text: 'getPaidSales',
        duration: 1.8,
        delay: 300,
        isShow: true,
        offset: -200
    },
    {
        id: 2,
        text: 'Multiplecurrencies',
        duration: 2.1,
        delay: 500,
        isShow: true,
        offset: -200
    },
    {
        id: 3,
        text: 'noSetupFree',
        duration: window.innerWidth <= 480 ? 1.8 : 2.4,
        delay: window.innerWidth <= 480 ? 500 : 700,
        isShow: true,
        isMobile: true,
        offset: -200
    },
    {
        id: 4,
        text: 'addToYourWebsite',
        duration: window.innerWidth <= 480 ? 2.1 : 2.7,
        delay: window.innerWidth <= 480 ? 700 : 900,
        isShow: true,
        isMobile: true,
        offset: -200
    }
]
export const isEnglishContent = (language) => {
    return language && language?.includes('en')
}

export const arabicClass = (lan) => {
    lan === "ar" ? document.body.classList.add('rtl') : document.body.classList.remove('rtl')
}

export const filterArray = (arr1, arr2) => {
    const filtered = arr1.filter(el => {
       return arr2.indexOf(el) === -1;
    });
    return filtered;
 };