import { combineReducers } from 'redux';
import loader from './loader/reducer';
import signIn from './auth/reducer';
import toastReducer from './toast/reducer';
import mainpageData from './MainPage/reducer';

export default combineReducers({
    loader,
    signIn,
    toastReducer,
    mainpageData,
});