import { useState, useEffect } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import CustomButton from '../Common/CustomButton'
import CommonSuccessModal from '../Common/CommonSuccessModal'
import SignInSignUpModal from '../Landing/SignInSignUpModal'
import { Link as ScrollTo } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import { signUpSuccessAction } from '../../redux/auth/action'
import { useTranslation } from 'react-i18next';
import brandLogo from '../../assets/images/logo-new.svg'
import LanguageSelector from '../Landing/LanguageSelector'
import { setupFetchId } from '../../redux/MainPage/action'

const Header = ({ custom, closeMenu, setCloseMenu, modify, isHome, isArabic, path }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const isSignupSuccess = useSelector(({ signIn }) => signIn.signUpSucess)
    const isDesktopView = useSelector(({ mainpageData }) => mainpageData?.isDesktopView)
    const [modalShow, setModalShow] = useState(false);
    const [menuExpand, setMenuExpand] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const handleClose = () => setModalShow(false);

    function logit() {
        setScrollY(window.pageYOffset);
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", logit);
        };
    });

    const isArabicContent = () => i18n.language === "ar"

    useEffect(() => {
        if (isSignupSuccess) setShowSuccessModal(true)
    }, [isSignupSuccess]);


    const handleSuccessModalClose = () => {
        setShowSuccessModal(false)
        dispatch(signUpSuccessAction(false))
    }


    const handleExpendMenu = (value) => {
        if (document.body.scrollWidth > 991) {
            menuExpand && setMenuExpand(false)
        } else {
            if (value === true && menuExpand) {
                if (closeMenu !== undefined) {
                    setCloseMenu(false)
                }
            } else {
                if (value !== true) {
                    setMenuExpand(!menuExpand)
                    if (closeMenu !== undefined) {
                        setCloseMenu(!menuExpand)
                    }
                }
            }
        }
    }

    const handleNavigation  = (id) => {
        history.push(isArabicContent() ? "/ar" : "/")
        dispatch(setupFetchId(id))
    }

    const handleModifyHeader = (id) => {
        !modify ? handleExpendMenu() : handleNavigation(id)
    }

    return (
        <>
            <SignInSignUpModal dialogClassName="signup-modal" handleClose={handleClose} modalShow={modalShow} isSignUpModel />
            <header className={scrollY > 50 ? "sticky" : null} onClick={() => handleExpendMenu(true)}>
                <Navbar expand="lg" fixed="top" expanded={menuExpand}>
                    <Container className='px-0'>
                        <Link to={isArabicContent() ? "/ar" : "/"} className="navbar-brand mr-auto md-order-2" onClick={() => window.scrollTo(0, 0)}>
                            <img src={brandLogo} alt="logo" />
                        </Link>
                        {!custom ?
                            <>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" className="md-order-1" id="button-collapse" onClick={handleExpendMenu} />
                                <Navbar.Collapse id="basic-navbar-nav" >
                                    <Nav className="m-auto">
                                        <ScrollTo
                                            activeClass="active"
                                            className="nav-link"
                                            to="howdoeswork"
                                            spy={true}
                                            onClick={() => handleModifyHeader("#howdoeswork")}
                                            smooth={true}
                                            duration={800}
                                            offset={-50}
                                        >
                                            {t('howItWorks')}
                                        </ScrollTo>
                                        <ScrollTo
                                            activeClass="active"
                                            className="nav-link"
                                            to="notification-email"
                                            spy={true}
                                            onClick={() => handleModifyHeader("#notification-email")}
                                            smooth={true}
                                            duration={800}
                                            offset={-50}
                                        >
                                            {t('features')}
                                        </ScrollTo>
                                        <ScrollTo
                                            activeClass="active"
                                            className="nav-link"
                                            to="pricing"
                                            spy={true}
                                            onClick={() => history.push(isArabicContent() ? '/pricing-plans/ar' : '/pricing-plans')}
                                            smooth={true}
                                            duration={800}
                                            offset={-50}
                                        >
                                            {t('pricing')}
                                        </ScrollTo>
                                        <ScrollTo
                                            activeClass="active"
                                            className="nav-link"
                                            to="faq-gift-card"
                                            spy={true}
                                            onClick={() => history.push(isArabicContent() ? '/faq-page/ar' : '/faq-page')}
                                            smooth={true}
                                            duration={800}
                                            offset={-70}
                                        >
                                            {t('faq')}
                                        </ScrollTo>
                                        {!isDesktopView ? <LanguageSelector {...{ setMenuExpand, isHome, isArabic, path }} /> : null}
                                    </Nav>
                                </Navbar.Collapse>
                            </> : null
                        }
                        <div className="header-btn d-flex flex-wrap align-items-center md-order-3">
                        {isDesktopView? <LanguageSelector {...{ setMenuExpand, isHome, isArabic, path }} /> : null}
                            <CustomButton className={modify ? "modify-header-button" : null} handalClick={() => history.push('/login')} >{t('login')}</CustomButton>
                            <CustomButton handalClick={() => history.push('/sign-up')}>{t('start_free_trial')}</CustomButton>
                        </div>
                    </Container>
                </Navbar>
            </header>
            <CommonSuccessModal
                handleClose={handleSuccessModalClose}
                modalShow={showSuccessModal}
                title={"Congratulations!"}
                message={"A confirmation email has been sent to your provided email address."} />
        </>
    )
}

export default Header
