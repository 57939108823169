import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { filterArray, isEnglishContent } from "../../utils/customFunction";
import { fetchMgcCountries } from '../../Api/SignupApi';
const removeCoutry = ["Israel", "Iraq" , "Libyan Arab Jamahiriya" , "Russia" , "Iran, Islamic Republic of Persian Gulf"]

export default function CustomCountrySelector({ name, formData, setFormData, error, label, handleCode, data, ...props }) {

    const [value, setValue] = useState(null);
    const [countryData, setCoutryData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const { i18n, t } = useTranslation()
    const [countriesObj, setcountriesObj] = useState();

    useEffect(() => {
        fetchCountries();
        
    },[])


    const fetchCountries = async () => {
        const res = await fetchMgcCountries();
        let obj = {}
        res?.data?.data?.country?.map((item)=>{
            obj = {
                ...obj,
                [item?.country_name]:  item?.country_code
            }
            return null
        })
        setcountriesObj(obj)
        const cloneData = [...res.data?.data?.country]
        const modData = filterArray(cloneData.map(data => data?.country_name), removeCoutry)
        modData.sort((a, b)=> {
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
        })
        setCoutryData(modData)  
    }

    const useStyles = makeStyles((theme) => ({

        countrySelect: {
            minWidth: '100%',
            position: "relative",
            marginBottom: "2px",
            marginRight: isEnglishContent(i18n.language) ? "12px" : "inherit",
            marginLeft: isEnglishContent(i18n.language) ? "inherit" : "12px",
            [theme.breakpoints.down("480")]: {
                marginRight: isEnglishContent(i18n.language) ? "6px" : "inherit",
                marginLeft: isEnglishContent(i18n.language) ? "inherit" : "6px",
            },
            [theme.breakpoints.down("425")]: {
                minWidth: '96px',
            },

            "& > *": {
                margin: 0,
                width: "100%",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#cccccc",
                borderRadius: "4px",
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: "#cccccc",
            },
            "& .MuiOutlinedInput-input": {
                padding: "18px 14px",
                paddingRight: isEnglishContent(i18n.language) ? "70px" : "14px",
                paddingLeft: isEnglishContent(i18n.language) ? "14px" : "70px",
                [theme.breakpoints.down('480')]: {
                    padding: isEnglishContent(i18n.language) ? "14px 70px 14px 14px" : "14px 14px 14px 70px",
                },
            },
            '& .MuiInputLabel-outlined': {
                transform: ' translate(14px, 20px) scale(1)',
                [theme.breakpoints.down('480')]: {
                    transform: ' translate(14px, 17px) scale(1)',
                    fontSize: '14px',
                },
            },
            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
                fontWeight: 600,
            },
            "& .MuiFormLabel-root": {
                color: "#626262",
                right: isEnglishContent(i18n.language) ? "inherit" : "28px",
                left: isEnglishContent(i18n.language) ? "0" : "inherit",
            },
            "& .MuiFormLabel-root.Mui-focused": {
                color: "#1B3D9A",
                fontWeight: 500,
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#1B3D9A",
            },
            "& .MuiInputBase-input": {
                color: "#000000 !important",
                fontWeight: 400,
            },
            "& MuiFormHelperText-root": {
                color: " #dc3545!important",
            },
            "& .MuiSelect-select": {
                backgroundColor: "transparent",
            },
            '& .MuiInputBase-root': {
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
                paddingRight: isEnglishContent(i18n.language) ? "30px !important" : "13px !important",
                paddingLeft: isEnglishContent(i18n.language) ? "13px !important" : "30px !important",
                [theme.breakpoints.down("1440")]: {
                    paddingRight: isEnglishContent(i18n.language) ? "55px" : "14px",
                    paddingLeft: isEnglishContent(i18n.language) ? "14px" : "55px",
                },
                [theme.breakpoints.down("480")]: {
                    padding: isEnglishContent(i18n.language) ? "4.5px 20px 4.5px 4px !important" : "4.5px 4px 4.5px 20px !important",
                },
            }
        },
        clearIcon: {
            color: "#ff302d",
            position: "absolute",
            cursor: "pointer",
            top: "18px",
            right: "24px",
            width: "auto",
            backgroundColor: "#fff",
            [theme.breakpoints.down("479")]: {
                top: "11px",
                right: "6px",
            },
            "& svg": {
                fontSize: "30px",
                [theme.breakpoints.down("479")]: {
                    fontSize: "26px",
                },
                [theme.breakpoints.down("425")]: {
                    fontSize: "24px",
                },
            },
        },
    }));

    const classes = useStyles();
    const theme = createTheme({ direction: isEnglishContent(i18n.language) ? 'ltr' : 'rtl' });

    const handleClear = (item) => {
        setValue(null)
        setFormData({ ...formData, [item]: "" });
        setInputValue(null)
    }

    return (
        <ThemeProvider theme={theme} >
            <Autocomplete
                {...props}
                disableClearable
                value={value}
                noOptionsText={t('Invalid Country Name')}
                onChange={(e, newValue) => {
                    handleCode(countriesObj?.[newValue] || "")
                    setValue(newValue || "")
                }}
                getOptionLabel={(option) => option}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => { setInputValue(newInputValue) }}
                renderOption={(option) =>
                    <>
                        {option}
                    </>
                }
                id="controllable-states-demo"
                options={countryData}
                className={classes.countrySelect}
                renderInput={(params) => <>
                    <TextField  {...params} label={label} variant="outlined" />
                    {formData[name] ? (
                        <span className={`${classes.clearIcon} detail-custom-close`} onClick={() => handleClear(name)} >
                            {t('clear')}
                        </span>
                    ) : null}
                </>
                }
            />
            <p className="input-error-msg">{error[name] && `${t(error[name])}`}</p>
        </ThemeProvider>
    );
}
