import Api from './Api'

export const contactInquiry = async(payload) => {
    const response = await Api.post('/contacts', payload)
    return response
}

export const getFrontEnd = async() => {
    const response = await Api.get('/frontend-site')
    return response
}