import Api from './Api';

export const getGiftcardsType = async() => {
    const response = await Api.get("/business-detail/marketing-type")
    return response
}
export const getIndustry = async() => {
    const response = await Api.get("/business-detail/industry")
    return response
}
export const getBusiness = async() => {
    const response = await Api.get("/business-type")
    return response
}
export const getBusinessRole = async() => {
    const response = await Api.get("/tenant-creator-role")
    return response
}
export const getCountry = async() => {
    const response = await Api.get("/countries")
    return response
}
