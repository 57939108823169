import * as actions from "../../redux/actions";

export const PageAction = (payload) => {
  return async (dispatch) => {
    dispatch(actions.mainpageChange(payload));
  };
};
export const privacyData = (payload) => {
  return async (dispatch) => {
    dispatch(actions.privacyMessage(payload));
  };
};
export const termsData = (payload) => {
  return async (dispatch) => {
    dispatch(actions.termsMessage(payload));
  };
};

export const setDesktopView = (payload) => {
  return async (dispatch) => {
    dispatch(actions.setupDesktopView(payload))
  }
}

export const setupMobileShapeAdd = (payload) => {
  return async (dispatch) => {
    dispatch(actions.setupMobileShape(payload))
  }
}

export const userListData = (payload) => {
  return async (dispatch) => {
    dispatch(actions.listUser(payload))
  }
}
export const deleteUser = (payload) => {
  return async (dispatch) => {
    dispatch(actions.deleteUserList(payload))
  }
}
export const editUser = (payload) => {
  return async (dispatch) => {
    dispatch(actions.editUserList(payload))
  }
}

export const setupFetchId = (payload) => {
  return async (dispatch) => {
    dispatch(actions.fetchComponentId(payload))
  }
}
