import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from '../../utils/country.json'
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { defaultCode, isEnglishContent } from "../../utils/customFunction";

export default function CountrySelector({label, handleCode, ...props}) {

  const [value, setValue] = useState(defaultCode);
  const [inputValue, setInputValue] = useState('');
  const { i18n } = useTranslation()

  const useStyles = makeStyles((theme) => ({
  
    countrySelect: {
        minWidth: '130px',
        position: "relative",
        marginBottom: "2px",
        marginRight: isEnglishContent(i18n.language) ? "12px" : "inherit",
        marginLeft: isEnglishContent(i18n.language) ? "inherit" : "12px",
        [theme.breakpoints.down("480")]: {
          marginRight: isEnglishContent(i18n.language) ? "6px" : "inherit",
          marginLeft: isEnglishContent(i18n.language) ? "inherit" : "6px",
        },
        [theme.breakpoints.down("425")]: {
          minWidth: '96px',
        },


        "& > *": {
          margin: 0,
          width: "100%",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#cccccc",
          borderRadius: "4px",
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: "#cccccc",
        },
        "& .MuiOutlinedInput-input": {
          padding: "18px 14px",
          paddingRight: isEnglishContent(i18n.language) ? "70px" : "14px",
          paddingLeft: isEnglishContent(i18n.language) ? "14px" : "70px",
          [theme.breakpoints.down('480')]: {
            padding: isEnglishContent(i18n.language) ? "14px 70px 14px 14px" : "14px 14px 14px 70px",
          },
        },
        '& .MuiInputLabel-outlined': {
          transform: ' translate(14px, 20px) scale(1)',
          [theme.breakpoints.down('480')]: {
            transform: ' translate(14px, 17px) scale(1)',
            fontSize: '14px',
          },
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
          fontWeight: 600,
        },
        "& .MuiFormLabel-root": {
          color: "#626262",
          right: isEnglishContent(i18n.language) ? "inherit" : "28px",
          left: isEnglishContent(i18n.language) ? "0" : "inherit",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#318ed0",
          fontWeight: 500,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#318ed0",
        },
        "& .MuiInputBase-input": {
          color: "#000000 !important",
          fontWeight: 400,
        },
        "& MuiFormHelperText-root": {
          color: " #dc3545!important",
        },
        "& .MuiSelect-select": {
          backgroundColor: "transparent",
        },
        '& .MuiInputBase-root':{
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
            paddingRight: isEnglishContent(i18n.language) ? "30px !important" : "13px !important",
            paddingLeft: isEnglishContent(i18n.language) ? "13px !important" : "30px !important",
            [theme.breakpoints.down("1440")]: {
          paddingRight: isEnglishContent(i18n.language) ? "55px" : "14px",
          paddingLeft: isEnglishContent(i18n.language) ? "14px" : "55px",
        },
        [theme.breakpoints.down("480")]: {
          padding: isEnglishContent(i18n.language) ? "4.5px 20px 4.5px 4px !important" : "4.5px 4px 4.5px 20px !important",
        },
        }
      },
  }));

    const classes = useStyles();
    const theme = createTheme({ direction: isEnglishContent(i18n.language) ? 'ltr' : 'rtl' });

  return (
    <ThemeProvider theme={theme} >
      <Autocomplete
        {...props}
        disableClearable
        value={value}
        onChange={(e, newValue) => {
            handleCode(newValue || {value: ""})
            setValue(newValue || "")
        }}
        getOptionLabel={(option) => option.label}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => {setInputValue(newInputValue)}}
        renderOption={(option) =>
            <>
              {option.label}
            </>
            }
        id="controllable-states-demo"
        options={countries}
        className={classes.countrySelect}
        renderInput={(params) => <TextField  {...params} label={label} variant="outlined" />}
      />
    </ThemeProvider>
  );
}
