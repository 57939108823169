import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap'
import TextField from '../Common/TextField'
import CustomButton from "../Common/CustomButton";
import { LoaderAction } from "../../redux/loader/action";
import { toastMessage } from "../../redux/actions";
import { emailValidation } from "../../utils/regex";
import { sendCode } from "../UserPool/Cognito";
import { Link, useHistory } from 'react-router-dom'
// import signUpImg from "../../assets/images/signup-img.png"
import { useTranslation } from "react-i18next";


const ForgotPassWord = ({ setForgotPasswordModel }) => {
    const history = useHistory();
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [username, setEmails] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleForgotPassword = async () => {
        if (username && emailValidation(username)) {
            dispatch(LoaderAction(true));
            try {
                await sendCode(username)
                history.push(`set-new-password?email=${username}`);
                dispatch(toastMessage({
                    status: true,
                    message: "Verification code send",
                    type: "success",
                }));
                dispatch(LoaderAction(false));
            } catch (err) {
                dispatch(toastMessage({
                    status: true,
                    message: err.message || t("somthingWentWrong"),
                    type: "error",
                }));
                dispatch(LoaderAction(false));
            }
        } else {
            setErrorMessage("Invalid email address");
        }
    };

    return (
        <Row className="align-items-center">
            <Col md={6}>
                <div className="signup-img">
                    <img src={""} alt="" />
                </div>
            </Col>
            <Col md={6}>
                <div className="signup-wrap">
                    <h3>Forgot Password</h3>
                    <div className="signup-form">
                        <FormGroup>
                            <FormLabel>Email<span className="required">*</span></FormLabel>
                            <TextField
                                type="email"
                                name="email"
                                value={username}
                                onChange={(e) => setEmails(e.target.value)}
                            />
                            {errorMessage && (
                                <p color="error" variant="body1">
                                    {errorMessage}
                                </p>
                            )}
                        </FormGroup>

                    </div>
                    <CustomButton
                        handalClick={handleForgotPassword}
                    >
                        Submit
                    </CustomButton>
                    
                    <div className="bottom-text">
                        Click to go <Link to="/" onClick={() => setForgotPasswordModel(false)}>Back</Link>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ForgotPassWord
