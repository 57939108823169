import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormControl, FormGroup, Row, Spinner } from 'react-bootstrap'
import Button from "@material-ui/core/Button";
import { toastMessage } from "../../redux/actions";
import { SignInValidation } from '../SignIn/SignInValidation';
import { getUserDomain } from '../UserPool/Cognito';
import { X } from "react-bootstrap-icons";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import giftcardsLogo from "../../assets/images/logo-dark-bg.svg"
import shapeSetup1 from "../../assets/images/mobile-shape-setup-3.svg";
import { purify } from "../../utils/dompurify";
import CustomInput from "../Common/CustomInput"

const initialState = {
    email: "",
}

const LogInModal = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch();
    const isMobileView = useSelector(({ mainpageData }) => mainpageData.isIncludeMobileShape);
    const history = useHistory();
    const [formData, setFormData] = useState(initialState);
    const [formError, setErrors] = useState({ domain: '' });
    const [isSelected, setIsSelected] = useState(null)
    const [subDomain, setSubDomain] = useState('')
    const [domain, setDomain] = useState([]);
    const [isDomain, setIsDomain] = useState(false)
    const [loading, setLoading] = useState(false)
    const errorMessage = ""
    const lang = localStorage.getItem("i18nextLng")

    const handleInputChange = (event) => {
        event.persist();
        const { name, value: changedValue } = event.target
        const value = purify(changedValue)
        !!value && setIsDomain(false)
        const cloneErrors = formError
        cloneErrors.domain = ''
        setErrors(cloneErrors);

        setFormData((formData) => ({
            ...formData,
            [name]: value,
        }));
        setErrors(formError => ({
            ...formError,
            [name]: SignInValidation(name, value)
        }));
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        Object.keys(formData).forEach(name => {
            const error = SignInValidation(name, formData[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            validationErrors['email'] && setIsDomain(false)
            return;
        }
        if (isDomain && !isSelected) {
            setErrors({ ...formError, domain: t('pleaseSelectDomain') });
            return
        }
        // dispatch(LoaderAction(true));
        let userId = formData.email

        try {
            setLoading(true);
            const response = await getUserDomain(userId)
            const { data } = response
            if (data) {
                setIsDomain(true)
                setDomain([...data])
                setLoading(false);
                // dispatch(LoaderAction(false));
                // window.location.replace(`https://${data?.domain}/login`)
            }
        } catch (error) {
            setLoading(false);
            dispatch(toastMessage({
                status: true,
                message: error?.response?.message || (error?.response?.data?.detail && t(`${error?.response?.data?.detail}`)) || "Something went wrong",
                type: "error"
            }));
            setIsDomain(false)
        }
    }

    const redirectSubDomain = (e) => {
        e.preventDefault()
        window.location.replace(`https://${subDomain}/login?email=${formData.email}`)
    }

    const handleChange = (data) => {
        const { domain, key } = data
        setIsSelected((isSelected !== key) ? key : null)
        setSubDomain(domain)
        // setSuccessClass(successClass => !successClass)
        // data && setSubDomain(data)
    }

    const handleRemove = (name) => {
        setFormData((formData) => ({
            ...formData,
            [name]: "",
        }));
        setErrors(formError => ({
            ...formError,
            [name]: ""
        }));
    }

    // const handleSignIn = async () => {
    //     let validationErrors = {};

    //     Object.keys(formData).forEach(name => {
    //         const error = SignInValidation(name, formData[name]);
    //         if (error && error.length > 0) {
    //             validationErrors[name] = error;
    //         }
    //     });

    //     if (Object.keys(validationErrors).length > 0) {
    //         setErrors(validationErrors);
    //         return;
    //     }
    //     dispatch(LoaderAction(true));
    //     let password = formData.password
    //     let username = formData.email
    //     try {
    //         const result = await signInWithEmail(username, password)
    //         let token = {
    //             accessToken: result.getAccessToken().getJwtToken(),
    //             idToken: result.getIdToken().getJwtToken(),
    //             refresh: result.getRefreshToken().getToken()
    //         }

    //         dispatch(LoaderAction(false));
    //         try {
    //             const userData = await getAttributes()
    //             let userInfo = {}
    //             userData.forEach(item => {
    //                 let key = item.Name
    //                 let val = item.Value
    //                 userInfo[key] = val
    //             })
    //             token.userInfo = userInfo
    //             await axios.get(`${process.env.REACT_APP_API_SERVER_URL2}/get-domain`,{
    //                 headers: {
    //                     "Authorization":`${token.idToken}`
    //                 }
    //             }).then(response =>{
    //                 const userData = response.data
    //                 if(userData){
    //                     setTimeout(() =>{
    //                         window.location.replace(`https://${userData?.data?.domainName}`)
    //                     },1000)
    //                     history.go(1)    
    //                 }
    //             }).catch(error => console.log(`error`, error))
    //             dispatch(signInSuccess(token));
    //             setLoggedUser(token)
    //             dispatch(toastMessage({
    //                 status: true,
    //                 message: "sign in successful",
    //                 type: "success",
    //             }));

    //         } catch (err) {

    //         }
    //     } catch (err) {
    //         dispatch(LoaderAction(false));
    //         dispatch(toastMessage({
    //             status: true,
    //             message: err.message || "Something went wrong please try again",
    //             type: "error",
    //         }));
    //     }
    // }

    return (
        <div className="signup-modal login-container">
            <div className="signup_setup shape_one login-modal">
                {isMobileView ? <img src={shapeSetup1} alt="logo" /> : null}
            </div>
            <div className="modal-header">
                <div className="modal-logo" onClick={() => lang === "en" ? history.push("/") : history.push("/ar")} >
                    <img src={giftcardsLogo} alt="logo" />
                </div>
                <button type="button" className="close" onClick={() => lang === "en" ? history.push("/") : history.push("/ar")}> <X /> </button>
            </div>
            <Row className='justify-content-center m-0'>
                <div className='flex-box new-account-box'>
                    <h2>{t('signIn')}</h2>
                    <p>{t('domainloginMessage')}</p>
                    <Form onSubmit={(isSelected) ? redirectSubDomain : handleSignIn} >
                        <div className="signup-form">
                            <div className="set-email mt-5">
                                <CustomInput type="text" name="email" onChange={handleInputChange} value={formData.email} label={t('email')} />
                                {formData.email ? (
                                    <span onClick={() => handleRemove("email")} className="custom-close">
                                        {t('clear')}
                                    </span>
                                ) : null}
                                <p className="input-error-msg text-danger text-left">{formError["email"] && t(`${formError["email"]}`)}</p>
                            </div>
                            {
                                isDomain && <FormGroup className='mt-5'>
                                    <h4 className="text-left" >{t("yourDomains")}</h4>
                                    <h6 className="text-left mb-3" >{t("selectDomainToProceed")}</h6>
                                    <>
                                        {
                                            domain.map((data, index) => {
                                                return (
                                                    <div key={index} className="d-flex" >
                                                        <FormControl
                                                            className={`my-1 cursor-pointer ${isSelected === (index + 1) ? `success_domain` : ``}`}
                                                            value={isSelected === (index + 1) ? `${data.domain} ✓` : data.domain}
                                                            readOnly={true}
                                                            onClick={() => handleChange({ domain: data.domain, key: (index + 1) })}
                                                            label={data.domain}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    {
                                        (!isSelected && isDomain) ? <p className="text-danger text-left">{formError.domain && t(`${formError.domain}`)}</p> : null
                                    }
                                </FormGroup>
                            }
                            {/* <FormGroup>
                        <FormLabel>Password</FormLabel>
                        <TextField
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            maxLength="32"
                        />
                        {formError.password && (
                            <p style={{ color: 'red' }} align="left">{formError.password}</p>
                        )}
                    </FormGroup> */}
                        </div>
                        {/* <Link className="float-right mb-2" to="/" onClick={() => setForgotPasswordModel(true)}>Forgot Password ?</Link> */}
                        <div className="actionBtn text-center">
                            <Button
                                disabled={loading}
                                className='mb-2 w-100'
                                type='submit'
                            >
                                {!isSelected ? t('signIn') : t('proceed')}
                                {(!isSelected && loading) ?
                                    <Spinner className="spinner-loaderIcon ml-2" animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                    </Spinner> : null
                                }
                            </Button>
                        </div>
                        {errorMessage &&
                            <p color="error" variant="body1">{errorMessage}</p>}
                        <small className='have-account'>{t('dontHaveAccount')}<span className='ml-1' onClick={() => history.push('/sign-up')}>{t('signUp')}</span> </small>
                    </Form>
                </div>
            </Row>
        </div>
    )
}

export default LogInModal