const validmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export const SignInValidation = (name, value, formData) => {
  switch (name) {
    case "email":
            if (!value || value.trim() === "") {
                return 'emailIsRequired';
            } else if (!value.match(validmail)) {
                return 'validEmail'
            } else {
                return ''
            }
    case "password":
        if (!value) {
            return "passwordRequired";
        } else if (value.length < 8 || value.length > 15) {
            return "passwordLength";
        } else if (!value.match(/[a-z]/g)) {
            return "atleastLowercase";
        } else if (!value.match(/[A-Z]/g)) {
            return "atleastUppercase";
        } else if (!value.match(/[0-9]/g)) {
            return "atLeastOneDigit";
        } else {
            return "";
        }
    default: {
        return "";
    }
  }
};