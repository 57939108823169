import React from 'react'
import CustomModal from './CustomModal'

const CommonSuccessModal = ({ handleClose, modalShow, title, message }) => {
    return (
        <CustomModal
            dialogClassName="success-modal"
            handleClose={handleClose}
            modalShow={modalShow}
        >
            <div className="text-center px-3 pb-3">
                <div className="success-icon py-3">
                    <i className="ionicons ion-checkmark-circled"></i>
                </div>
                { title && <h2 className="text-dark mb-3">{ title }</h2> } 
                { message && <p className="px-3 text-dark m-auto success-note">{ message }</p> }      
            </div>
        </CustomModal>
    )
}

export default CommonSuccessModal