import Api from './Api'
import axios from 'axios'

export const authDomain = async (payload, source, setCancelToken) => {
    setCancelToken(source)
    const response = await Api.post(`/vendor-accounts/validate`, payload)
    return response
}

export const userSignUp = async (payload, language) => {
    const response = await Api.post(`/vendor-accounts/sign-up?language=${language}`, payload)
    return response
}

export const saveSubscription = async (token, domain) => {
    const response = await Api.post(`/chargebee-subscription?domain=${domain}.${process.env.REACT_APP_DOMAIN}`, { token });
    return response
}

export const fetchMgcCountries = async () => {
    const response = await axios.get(`https://testapi.meritincentives.com/api/v1/country_list`);
    return response
}