import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from 'react-i18next';
import { isEnglishContent } from "../../utils/customFunction";

const CustomInput = ({
  label,
  type,
  value,
  onChange,
  helperText,
  name,
  ref,
  ...props
}) => {

  const { i18n } = useTranslation()
  
  const useStyles = makeStyles((theme) => ({
    customInput: {
      "& > *": {
        margin: 0,
        width: "100%",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#cccccc",
        borderRadius: "4px",
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#cccccc",
      },
      "& .MuiOutlinedInput-input": {
        padding: "18px 14px",
        paddingRight: isEnglishContent(i18n.language) ? "55px" : "14px",
        paddingLeft: isEnglishContent(i18n.language) ? "14px" : "55px",
        [theme.breakpoints.down("1440")]: {
          paddingRight: isEnglishContent(i18n.language) ? "55px" : "14px",
          paddingLeft: isEnglishContent(i18n.language) ? "14px" : "55px",
        },
        [theme.breakpoints.down("480")]: {
          padding: isEnglishContent(i18n.language) ? "14px 55px 14px 14px" : "14px 14px 14px 55px",
        },
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 20px) scale(1)",
        [theme.breakpoints.down("480")]: {
          transform: "translate(14px, 17px) scale(1)",
          fontSize: "14px",
        },
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
        fontWeight: 600,
      },
      "& .MuiFormLabel-root": {
        color: "#626262",
        right: isEnglishContent(i18n.language) ? "inherit" : "28px",
        left: isEnglishContent(i18n.language) ? "0" : "inherit",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1B3D9A",
        fontWeight: 500,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1B3D9A",
      },
      "& .MuiInputBase-input": {
        color: "#000000 !important",
        fontWeight: 400,
      },
      "& MuiFormHelperText-root": {
        color: " #dc3545!important",
      },
    },
  }));

  const classes = useStyles();
  const theme = createTheme({ direction: isEnglishContent(i18n.language) ? 'ltr' : 'rtl' });

  return (
    <ThemeProvider theme={theme} >
      <div className={classes.customInput } noValidate dir={isEnglishContent(i18n.language) ? 'ltr' : 'rtl'}>
        <TextField
          {...props}
          name={name}
          autoComplete="off"
          id="outlined-basic"
          type={type}
          label={label}
          helperText={helperText}
          variant="outlined"
          value={value}
          onChange={onChange}
        />
      </div>
    </ThemeProvider>
  );
};

export default CustomInput;
