import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import TextField from './TextField'
import countries from '../../utils/country.json'

const PhoneInput = ({ code, handleCode, value, handleChange }) => {
    const { t } = useTranslation()

    return (
        <>
            <Select options={countries} value={code} onChange={handleCode} />
            <TextField className="phone-number" type='number' value={value} onChange={handleChange} placeholder={t('phoneNumberWithoutOptional')} />
        </>
    )
}

export default PhoneInput