import React, { useState } from "react";
// import LogInModal from "./LogInModal";
import CustomModal from "../Common/CustomModal";
import ForgotPassWord from "./ForgotPassWord";
// import SignUpModal from "./SignUpModal";
import RegisterModal from "./RegisterModal";

const SignInSignUpModal = ({ modalShow, handleClose, dialogClassName }) => {
    // const [signInModel, setSignInModel] = useState(false)
    const [forgotPasswordModel, setForgotPasswordModel] = useState(false)
    return (
        <>
            {/* {
                !signInModel ?
                    <CustomModal {...{ modalShow, handleClose, dialogClassName, setSignInModel }}>
                        {
                            forgotPasswordModel ?
                                <ForgotPassWord {...{ setForgotPasswordModel }} /> :
                                <LogInModal {...{ setSignInModel, setForgotPasswordModel }} />
                        }
                    </CustomModal>
                    :
                    <CustomModal {...{ modalShow, handleClose, dialogClassName, setSignInModel }}>
                        <SignUpModal {...{setSignInModel, handleClose , modalShow }} />
                    </CustomModal>
            } */}
            {
                <CustomModal {...{ modalShow, handleClose, dialogClassName }} >
                   {forgotPasswordModel ? <ForgotPassWord {...{ setForgotPasswordModel }} />  : <RegisterModal {...{handleClose , modalShow, setForgotPasswordModel }} />}
                </CustomModal>
            }
        </>
    )
}

export default SignInSignUpModal
