import * as actions from "../../redux/actions";

export const signInSuccessAction = (payload) => {
  return async (dispatch) => {
    dispatch(actions.signInSuccess(payload))
  };
};

export const signInClearAction = () => {
  return async (dispatch) => {
    dispatch(actions.signInClear({}))
  };
};

export const signUpSuccessAction = (payload) => {
  return async (dispatch) => {
    dispatch(actions.signUpSuccess(payload))
  };
};