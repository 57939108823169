import constants from '../constants';
export const initialState = {
    status: false,
    message: "",
    type: "",
    closeFlag: false

};
const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.TOAST:
            return {
                ...state,
                status: action.payload.status,
                message: action.payload.message,
                type: action.payload.type,
                closeFlag: true
            };
        case constants.CLEAR_TOAST:
            return {
                ...state,
                status: false,
                message: "",
                type: '',
                closeFlag: false,
            }

        default:
            return state;
    }
};
export default toastReducer;
