const constants = {
  LOADER: "LOADER",
  MAIN_PAGE: "MAIN_PAGE",
  TOAST: "TOAST",
  CLEAR_TOAST: "CLEAR_TOAST",
  TERMS: "TERMS",
  PRIVACY: "PRIVACY",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  LOGIN_REQUEST_ERROR: "LOGIN_REQUEST_ERROR",
  CLEAR_FLAG: "CLEAR_FLAG",
  LIST_USER: "LIST_USER",
  DELETE_USER: "DELETE_USER",
  EDIT_USER: "EDIT_USER",
  EMAIL_BANNER: "EMAIL_BANNER",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  IS_DESKTOP_VIEW: "IS_DESKTOP_VIEW",
  IS_INCLUDE_SHAPE: "IS_INCLUDE_SHAPE",
  IS_FETCH_ID: "IS_FETCH_ID"
};

export default constants;