import React, { lazy, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Redirect, Switch } from "react-router-dom"
import { t } from "i18next"
import { getFrontEnd } from "../Api/UserApi"
import LogInModal from "../Component/Landing/LogInModal"
import RegisterModal from "../Component/Landing/RegisterModal"
import { toastMessage } from "../redux/actions"
import { LoaderAction } from "../redux/loader/action"
import { PageAction } from "../redux/MainPage/action"
import { isLoggedIn } from "../utils/authentication"
import FaqGiftCards from "../Component/Landing/FaqGiftCards"

const Home = lazy(() => import("../Component/Landing/Home"))
const Privacy = lazy(() => import("../Component/TermsCondition/Privacy"))
const Pricing = lazy(() => import("../Component/TermsCondition/Pricing"))
const Contact = lazy(() => import("../Component/TermsCondition/Contact"))
// const FAQ = lazy(() => import("../Component/TermsCondition/Faq"))
const Terms = lazy(() => import("../Component/TermsCondition/Terms"))
const Admin = lazy(() => import("../Component/Dashboard/Admin"))
const UserDashboard = lazy(() => import("../Component/Dashboard/UserDashboard"))
const UserList = lazy(() => import("../Component/Dashboard/UserList"))

const routes = [
    {
        path: "/",
        component: Home,
        exact: true,
        mainPath: "/"
    },
    {
        path: "/en",
        component: Home,
        exact: true,
        mainPath: "/"
    },
    {
        path: "/ar",
        component: Home,
        isArabic: true,
        exact: true,
        mainPath: "/"
    },
    {
        path: "/login",
        component: LogInModal,
        exact: true
    },
    {
        path: "/sign-up",
        component: RegisterModal,
        exact: true
    },
    {
        path: "/admin/user-dashboard",
        component: UserDashboard,
        isLogin: true,
    },
    {
        path: "/admin/user-list",
        component: UserList,
        isLogin: true,
    },
    {
        path: "/admin",
        component: Admin,
        isLogin: true,
    },
    {
        path: "/privacy-policy",
        component: Privacy,
        isAuth: false,
        exact: true,
        redirectUrl: '/',
        mainPath: "/privacy-policy"
    },
    {
        path: "/privacy-policy/en",
        component: Privacy,
        isAuth: false,
        exact: true,
        redirectUrl: '/',
        mainPath: "/privacy-policy"
    },
    {
        path: "/privacy-policy/ar",
        component: Privacy,
        isAuth: false,
        exact: true,
        isArabic: true,
        redirectUrl: '/',
        mainPath: "/privacy-policy"
    },
    {
        path: "/faq-page",
        component: FaqGiftCards,
        isAuth: false,
        exact: true,
        redirectUrl: '/',
        mainPath: "/faq-page",
    },
    {
        path: "/faq-page/en",
        component: FaqGiftCards,
        isAuth: false,
        redirectUrl: '/',
        mainPath: "/faq-page",
    },
    {
        path: "/faq-page/ar",
        component: FaqGiftCards,
        isAuth: false,
        redirectUrl: '/',
        isArabic: true,
        mainPath: "/faq-page",
    },
    {
        path: "/pricing-plans",
        component: Pricing,
        isAuth: false,
        exact: true,
        redirectUrl: '/',
        mainPath: "/pricing-plans"
    },
    {
        path: "/pricing-plans/en",
        component: Pricing,
        isAuth: false,
        exact: true,
        redirectUrl: '/',
        mainPath: "/pricing-plans"
    },
    {
        path: "/pricing-plans/ar",
        component: Pricing,
        isAuth: false,
        redirectUrl: '/',
        exact: true,
        isArabic: true,
        mainPath: "/pricing-plans"
    },
    {
        path: "/contact",
        component: Contact,
        isAuth: false,
        exact: true,
        mainPath: "/contact"
    },
    {
        path: "/contact/en",
        component: Contact,
        isAuth: false,
        exact: true,
        mainPath: "/contact"
    },
    {
        path: "/contact/ar",
        component: Contact,
        isAuth: false,
        exact: true,
        isArabic: true,
        mainPath: "/contact"
    },
    // {
    //     path: "/faq",
    //     component: FAQ,
    //     isAuth: false,
    //     exact: true,
    //     mainPath: "/faq"
    // },
    // {
    //     path: "/faq/en",
    //     component: FAQ,
    //     isAuth: false,
    //     exact: true,
    //     mainPath: "/faq"
    // },
    // {
    //     path: "/faq",
    //     component: FAQ,
    //     isAuth: false,
    //     exact: true,
    //     mainPath: "/faq"
    // 
    //},
    {
        path: "/terms-and-conditions",
        isAuth: false,
        exact: true,
        component: Terms,
        redirectUrl: '/',
        mainPath: "/terms-and-conditions"
    },
    {
        path: "/terms-and-conditions/en",
        isAuth: false,
        exact: true,
        component: Terms,
        redirectUrl: '/',
        mainPath: "/terms-and-conditions"
    },
    {
        path: "/terms-and-conditions/ar",
        isAuth: false,
        exact: true,
        isArabic: true,
        component: Terms,
        redirectUrl: '/',
        mainPath: "/terms-and-conditions"
    },
]

const SelectComponent = (Component, props, redirectUrl, isAuth, isLogin, isArabic, mainPath) => {
    const FinalComponent = <Component isArabic={isArabic} pathName={mainPath} {...props} />
    const FinalRedirectUrl = <Redirect to={{ pathname: redirectUrl }} />
    if (isAuth) return isLoggedIn() ? FinalRedirectUrl : FinalComponent
    if (isLogin) return isLoggedIn() ? FinalComponent : FinalRedirectUrl
    return FinalComponent
}

const Routing = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(LoaderAction(true));
        getData()
    // eslint-disable-next-line
    }, [])

    const getData = async() => {
        dispatch(LoaderAction(true));
        try {
            const response = await getFrontEnd()
            if(response?.data){
                dispatch(PageAction(response?.data))
                dispatch(LoaderAction(false))
            }
        } catch (error) {
            dispatch(toastMessage({
                status: true,
                message: error.message || error?.response?.data?.detail || t("somthingWentWrong"),
                type: "error",
            }));
        }
    };
    

    return (
        <Switch>
            {
                routes.map(({ component: Component, redirectUrl, isLogin, isAuth, isArabic, mainPath, ...routes }, index) => (
                    <Route
                        key={index}
                        {...routes}
                        render={props => SelectComponent(Component, props, redirectUrl, isAuth, isLogin, isArabic, mainPath)} />
                ))
            }
            <Redirect from="*" to="/" />
        </Switch>
    )
}


export default Routing